import {useState,useContext,createContext} from "react";



const ManualContext = createContext()


export const ManualProvider = ({children}) => {
    const [networks,setNetworks] =useState(null)
    const [cloudProvider,setCloudProviders] =useState(null)
    const [chain,setNodeChains] = useState("Node Chain")


    const setNetwork = (network) => {
        setNetworks(network)
      
    }
    const setNetCloudProvider = (provider) => {
        setCloudProviders(provider)
        
    }
    const setNodeChain = (chain) => {
        setNodeChains(chain)
        
    }
    return(
        <ManualContext.Provider
        value={{
            setNetwork,
            setNetCloudProvider,
            setNodeChain,
            networks,
            cloudProvider,
            chain,

            
        }}
        >
            {children}
        </ManualContext.Provider>
    )


}

export const useManual = () => useContext(ManualContext);

