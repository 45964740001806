import { useState, useContext, createContext } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [orgs, setOrgs] = useState(null);
  const [headerText, setHeaderText] = useState([]);
  const [token, setToken] = useState(null);

  // TODO: check for expiry when initing user
  const initUser = async (data) => {
    setUser(data.user);
    setOrgs(data.organizations);
    setToken(data.auth);
    runLogoutTimer(data.auth.expiryInSeconds * 1000);
    data.auth.expiryDate =
      new Date().getTime() + (data.auth.expiryInSeconds * 1000);
    localStorage.setItem(
      "user",
      JSON.stringify({
        ...data,
      })
    );

    // Set dashboard settings
    localStorage.setItem(
      "userSettings",
      JSON.stringify({
        dashboard: {
          navBar: { minimize: false }
        },
      })
    );
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem("user");
    localStorage.removeItem("userSettings");
    localStorage.removeItem("networks");
  };

  function runLogoutTimer(time) {
    setTimeout(() => {
      logout();
    }, time);
  }

  return (
    <AuthContext.Provider
      value={{ user, orgs, initUser, logout, headerText, setHeaderText }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
