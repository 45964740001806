import { useState, useContext, createContext, useRef } from "react";

const HeaderContext = createContext();

export const HeaderProvider = ({ children }) => {
  //const [mobileActive, setMobileActive] = useState(false);
  const mobileActive = useRef(false);
  const [minimizeNav, setMinimizeNav] = useState(false);

  return (
    <HeaderContext.Provider value={ { mobileActive, minimizeNav, setMinimizeNav } }>
      {children}
    </HeaderContext.Provider>
  )
}

export const useHeader = () => useContext(HeaderContext);
