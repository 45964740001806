import { projectReducer } from "./reducers";
import React, { useState, createContext, useReducer, useContext } from "react";

import { ADD_PROJECTS } from "./reducers";


const ProjectContext = createContext();

export const ProjectProvider = (props) => {
  const initiatState = {
    projects: [],
  };
  const [state, dispatch] = useReducer(projectReducer, initiatState);
 
  const [chain,setNodeChains] = useState(" node chain")


  const setNodeChain = (chain) => {
    setNodeChains(chain)
    
}
  const addProjects = (projects) => {
    dispatch({ type: ADD_PROJECTS, projects: projects });
  };


  return (
    <ProjectContext.Provider
      value={{
        projects: state.projects,
        addProjects: addProjects,
        chain,setNodeChain
      }}
    >
      {props.children}
    </ProjectContext.Provider>
  );
};

export const useProjects = () => useContext(ProjectContext);
