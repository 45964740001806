export const ADD_PROJECTS = "ADD_PROJECTS";

const addProjects = (projects, state) => {
  return { ...state, projects: [...projects] };
};

export const projectReducer = (state, action) => {
  switch (action.type) {
    case ADD_PROJECTS:
      return addProjects(action.projects, state);
    default:
      return state;
  }
};
