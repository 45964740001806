import React,{useEffect} from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../context/authContext";
import AppHeader from "../nav/AppHeader";
import Sidenav from "../nav/Sidenav";

export const Dashboard = ({ children }) => {
  const auth = useAuth();
  useEffect(() => {
    window.scrollTo(0, 0);

  })
  const userSettings = JSON.parse(localStorage.getItem('userSettings'));
  return auth.user ? (
    <div className="block-app">
      <Sidenav />
      <div className={`
      ${userSettings?.dashboard?.navBar?.minimize ? 'w-full xl:w-[calc(100%-70px)]' : 'w-full xl:w-[calc(100%-270px)]'}
        block-app__view`
      }
      >
        <AppHeader />
        <main className="main">{children}</main>
      </div>
    </div>
  ) : (
    <Navigate to="/login" replace />
  );
};
