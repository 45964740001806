import React, { useEffect, useRef } from "react";
import Substrate from "../../assets/svg/partners/white-substrate.svg";
import Polkadot from "../../assets/svg/partners/white-polkadot.svg";
import CloverFinance from "../../assets/svg/partners/white-clover.svg";
import Acala from "../../assets/svg/partners/white-acala.svg";
import Composable from "../../assets/svg/partners/white-composable.svg";
import FlowSvg from "../../assets/svg/partners/white-flow.svg";
import Moonbeam from "../../assets/svg/partners/white-moonbeam.svg";
import Body from "../../assets/img/body.png";
import Frame1 from "../../assets/img/frame1.png";
import LeftBlur from "../../assets/img/leftblur.png";
import RightBlur from "../../assets/img/rightblur.png";
import Blockops from "../../assets/img/blockops.png";
import Error from "../../assets/img/error.png";
import Check from "../../assets/img/check_circle.png";
import Logo from "../../assets/img/app-logo.png";
import Back from "../../assets/img/back.png";
import { containsSpecialChars } from "../../utils";
import { Link, useNavigate } from "react-router-dom";
import gsap from "gsap";

export const Partners = () => (
  <div className="flex w-full items-center gap-10">
    <div className="w-[80px] partner ml-5">
      <img src={Polkadot} alt="Polkadot" />
    </div>
    <div className="w-[80px] partner">
      <img src={Substrate} alt="Substrate" />
    </div>
    <div className="w-[80px] partner">
      <img src={FlowSvg} alt="FlowSvg" />
    </div>
    <div className="w-[80px] partner">
      <img src={Acala} alt="Acala" />
    </div>
    <div className="w-[80px] partner">
      <img src={Moonbeam} alt="Moonbeam" />
    </div>
    <div className="w-[80px] partner">
      <img src={CloverFinance} alt="CloverFinance" />
    </div>
    <div className="w-[80px] partner mr-5">
      <img src={Composable} alt="Composable" />
    </div>
  </div>
);

export const ErrorText = (props) => {
  return (
    <div className="relative w-full flex gap-2 mt-2">
      <img width={20} src={Error} alt="err" className="absolute left-0"/>
      <p className="ml-7 w-full">{props.text}</p>
    </div>
  )
}

export const PasswordCheck = ({ password, relative }) => {
  return (
    <div className={`
      ${'flex flex-col w-[230px] gap-1 mt-3 text-[12px] right-[-250px] top-5'}
      ${relative ? '' : 'xl:absolute'}
      `}
    >
      <p className="">Password must contain at least</p>
      <div className={`${password.length > 7 ? 'text-[#34B431]' : 'text-[#C63737]'} flex gap-2`}>
        <div className="">
          <img src={password.length > 7 ? Check : Error} alt="err" width={20} />
        </div>
        8 or more characters
      </div>
      <div className={`${/[A-Z]/.test(password) ? 'text-[#34B431]' : 'text-[#C63737]'} flex gap-2`}>
        <div className="">
          <img src={/[A-Z]/.test(password) ? Check : Error} alt="err" width={20} />
        </div>
        1 uppercase character
      </div>
      <div className={`${/[a-z]/.test(password) ? 'text-[#34B431]' : 'text-[#C63737]'} flex gap-2`}>
        <div className="">
          <img src={/[a-z]/.test(password) ? Check : Error} alt="err" width={20} />
        </div>
        1 lowercase character
      </div>
      <div className={`${/[0-9]/.test(password) ? 'text-[#34B431]' : 'text-[#C63737]'} flex gap-2`}>
        <div className="">
          <img src={/[0-9]/.test(password) ? Check : Error} alt="err" width={20} />
        </div>
        1 number
      </div>
      <div className={`${containsSpecialChars(password) ? 'text-[#34B431]' : 'text-[#C63737]'} flex gap-2`}>
        <div className="">
          <img src={containsSpecialChars(password) ? Check : Error} alt="err" width={20} />
        </div>
        1 special character
      </div>
    </div>
  )
}

export const Auth = ({ children, path }) => {

  const renderCount = useRef(1);

  const tl1 = gsap.timeline();
  const tl2 = gsap.timeline();

  useEffect(() => {
    renderCount.current === 1
    &&
    tl1.fromTo(
      '.partner-1',
      {
        xPercent: -100,
      },
      {
        xPercent: 100,
        duration: 20,
        ease: 'linear',
        repeat: -1,
      },
      ""
    ) &&
    tl2.fromTo(
      '.partner-2',
      {
        xPercent: -100,
        opacity: 1,
      },
      {
        xPercent: 100,
        opacity: 1,
        duration: 20,
        ease: 'linear',
        repeat: -1,
        delay: 10,
      },
      ""
    )
  },[tl1, tl2])

  return (
    <div className="pb-20 flex flex-col">
      {
        path === "signup" &&
        (<p className="text-[#13141B99] absolute text-[16px] self-end mt-16 pr-10 md:pr-20">
          Already have an account?
          <Link className="text-blue" to="/login">
            &nbsp;Log in
          </Link>
        </p>)
      }
      <div className="w-full flex flex-row justify-between gap-0">
        <div className="hidden md:flex relative flex flex-col items-center w-[45%] lg:w-[25%] lg:h-[100vh] min-h-[750px] 2xl:min-h-[900px] 2xl:h-[90vh] ml-5 mt-5 bg-[#0060FF] rounded-[30px] py-10 pt-16">
          <div className="self-start ml-14">
            <img src={Blockops} alt="blockops" width={130} className="mb-10" />
            <h2 className="form-header lg:w-[80%] text-white text-[20px] lg:text-[25px] 2xl:text-[30px] leading-[38px] font-[400] mr-5">
            Connecting you to the world of web3 through API’S
            </h2>
          </div>
          <div className="relative flex flex-col mt-10 w-full">
            <img src={Body} alt="pic1" className="pl-14 w-[100%] self-end" />
            <img src={Frame1} alt="pic2" className="pop-out absolute w-[85%] top-[50%] right-0 self-end"/>
          </div>
          <div className="flex items center w-full absolute bottom-10 px-0">
            <div className="absolute left-0 mt-10 xl:mt-0 z-[99999]">
              <img src={LeftBlur} alt="" width={70} />
            </div>
            <div className="w-full relative partners px-10 mt-10 xl:mt-0 flex overflow-hidden">
              <div className="partner-1"><Partners /></div>
              <div className="partner-2 absolute ml-[-40pxs]"><Partners /></div>
            </div>
            <div className="absolute right-0 mt-10 xl:mt-0 z-[99999]">
              <img src={RightBlur} alt="" width={70} />
            </div>
          </div>
        </div>
        <div className="flex w-full lg:w-[75%] flex-col">
          {children}
        </div>
      </div>
    </div>
  );
};

export const ResetAuth = ({ children }) => {
  const navigate = useNavigate();

  return (
    <div className="w-full h-[100vh] flex flex-col items-center px-3">
      <button
      onClick={() => navigate(-1)}
        className="absolute flex items-center gap-5 text-[13px] font-[400] left-7 md:left-20 top-10 md:top-20"
      >
        <img src={Back} alt="logo" className="min-hide-logo w-[30px]" />
        <span>Go back</span>
      </button>
      <div className="absolute top-[150px]">
        <img src={Logo} alt="logo" className="min-hide-logo w-[140px] md:w-[170px]" />
      </div>
      <div className="w-full mt-[100px]">
        {children}
      </div>
    </div>
  )
};
